<template>
    <modal
        class="convert-multipart-confirmation"
        :adaptive="true"
        name="convert-multipart-confirmation"
        height="auto"
        width="550"
    >
        <div class="close-modal" @click="$modal.hide('convert-multipart-confirmation')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="content">
            <h1>Convert to Multipart</h1>
            <p>You are about to turn this Single Memo into a Multipart Memo. </p>
            <div class="actions ml-auto">
                <button type="button" class="btn btn-white" @click="$modal.hide('convert-multipart-confirmation')">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary ml-3" @click="$emit('convert-to-multipart')">
                    Create
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "ConvertMultipartConfirmation"
}
</script>

<style lang="scss" scoped>
.convert-multipart-confirmation {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal{
        background-color: #1C1C1E;
        border-radius: 10px;
        box-shadow: none;

        .content {
            padding: 25px 20px;
            display: flex;
            flex-direction: column;

            h1 {
                font-weight: 600;
                font-size: 20px;
                line-height: 20px;
            }

            p {
                margin-top: 35px;
                margin-bottom: 45px;
                font-size: 18px;
                color: rgba(235, 235, 245, 0.6);
                text-align: center;
            }
        }
    }
}
</style>
